import { createAction } from '@reduxjs/toolkit';

export const updateAlert = createAction<{
  open: boolean;
  message?: string;
  autoHideDurationMs?: number;
}>('alert/updateAlert');

export const showAlert = createAction<string>('alert/open');
export const closeAlert = createAction<void>('alert/close');
