import { ChainId } from './chainId';

enum Environments {
  MAINNET = 'https://etherscan.io',
  ROPSTEN = 'https://ropsten.etherscan.io',
  RINKEBY = 'https://rinkeby.etherscan.io',
  GOERLI = 'https://goerli.etherscan.io',
  KOVAN = 'https://kovan.etherscan.io',
}

export function toEtherscanUrl(chainId: ChainId, txHash: string): string {
  switch (chainId) {
    case ChainId.MAINNET:
      return `${Environments.MAINNET}/tx/${txHash}`;
    case ChainId.ROPSTEN:
      return `${Environments.ROPSTEN}/tx/${txHash}`;
    case ChainId.RINKEBY:
      return `${Environments.RINKEBY}/tx/${txHash}`;
    case ChainId.GOERLI:
      return `${Environments.GOERLI}/tx/${txHash}`;
    case ChainId.KOVAN:
      return `${Environments.KOVAN}/tx/${txHash}`;
    default:
      throw new Error('Unknown chain id: ' + chainId);
  }
}

export function toOpenSeaUrl(chainId: ChainId): string {
  if (chainId === ChainId.MAINNET) {
    return 'https://opensea.io/account';
  } else {
    return 'https://testnets.opensea.io/account';
  }
}
