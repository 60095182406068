import { createReducer } from '@reduxjs/toolkit';
import initialState, { ModalType } from './state';
import { closeModal, openModal, updateModal } from './actions';

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateModal, (state, action) => {
      state.open = action.payload.open;
      state.type = action.payload.type ?? ModalType.UNSPECIFIED;
    })
    .addCase(openModal, (state, action) => {
      state.open = true;
      state.type = action.payload;
    })
    .addCase(closeModal, (state, action) => {
      state.open = false;
      state.type = ModalType.UNSPECIFIED;
    })
);
