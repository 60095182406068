import styled from '@emotion/styled';
import { Button as MuiButton, CircularProgress, ButtonProps as MuiButtonProps } from '@mui/material';
import { LightTooltip } from '../Tooltip';

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  color?: 'grey' | 'blue' | 'blue-flat' | 'dark' | 'green' | 'pink' | 'dark-pink' | 'yellow' | 'black' | 'white';
  dropShadow?: boolean;
  loading?: boolean;
  loadingText?: string;
  disabledText?: string;
  forceShowDisabledText?: boolean;
  tooltip?: string;
}

const defaultColor = 'black';

const ButtonBase = ({
  color,
  dropShadow,
  loading,
  loadingText,
  disabled,
  disabledText,
  forceShowDisabledText = false,
  tooltip,
  ...props
}: ButtonProps) => {
  return !tooltip || tooltip === '' ? (
    <MuiButton disabled={loading || disabled} {...props} {...(dropShadow && { variant: 'contained' })}>
      {!loading && (disabled || forceShowDisabledText) && disabledText ? (
        <span>{disabledText}</span>
      ) : (
        <span>{props.children}</span>
      )}
      {loading && (
        <div className="button-loading">
          {loadingText && <span>{loadingText}</span>}
          <CircularProgress size="1.3rem" />
        </div>
      )}
    </MuiButton>
  ) : (
    <LightTooltip title={tooltip || ''} arrow>
      <span>
        <MuiButton disabled={loading} {...props} {...(dropShadow && { variant: 'contained' })}>
          <span>{props.children}</span>
          {loading && (
            <div className="button-loading">
              {loadingText ? <span>{loadingText}</span> : <></>}
              <CircularProgress size="1.3rem" />
            </div>
          )}
        </MuiButton>
      </span>
    </LightTooltip>
  );
};

export const Button = styled(ButtonBase)<ButtonProps>`
  border-radius: 24px;
  align-self: center;
  padding: 8px 16px;
  text-transform: none;
  font-family: var(--font);
  & > span {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    transition: opacity 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    opacity: ${({ loading }: ButtonProps) => (loading ? 0 : 1)};
  }

  & > div {
    position: absolute;
    line-height: 100%;
    transition: opacity 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    opacity: ${({ loading }: ButtonProps) => (loading ? 1 : 0)};
  }

  .button-loading {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 8px;
    width: 100%;
    justify-content: center;

    & > span {
      align-self: center;
    }
  }

  box-shadow: ${({ disabled }: ButtonProps) => (disabled ? 'var(--button-shadow-color-disabled)' : '')};
  color: ${({ disabled, color = defaultColor }: ButtonProps) => {
    if (disabled) return 'var(--button-text-color-disabled) !important';
    switch (color) {
      case 'grey':
        return 'var(--button-text-color-grey)';
      case 'blue':
        return 'var(--button-text-color-blue)';
      case 'blue-flat':
        return 'var(--button-text-color-blue-flat)';
      case 'dark':
        return 'var(--button-text-color-dark)';
      case 'green':
        return 'var(--button-text-color-green)';
      case 'pink':
        return 'var(--button-text-color-pink)';
      case 'dark-pink':
        return 'var(--button-text-color-dark-pink)';
      case 'yellow':
        return 'var(--button-text-color-yellow)';
      case 'black':
        return 'var(--button-text-color-black)';
      case 'white':
        return 'var(--button-text-color-white)';
    }
  }};

  background-color: ${({ disabled, color = defaultColor }: ButtonProps) => {
    if (disabled) return 'var(--button-bg-color-disabled)';
    switch (color) {
      case 'grey':
        return 'var(--button-bg-color-grey)';
      case 'blue':
        return 'var(--button-bg-color-blue)';
      case 'blue-flat':
        return 'var(--button-bg-color-blue-flat)';
      case 'dark':
        return 'var(--button-bg-color-dark)';
      case 'green':
        return 'var(--button-bg-color-green)';
      case 'pink':
        return 'var(--button-bg-color-pink)';
      case 'dark-pink':
        return 'var(--button-bg-color-dark-pink)';
      case 'yellow':
        return 'var(--button-bg-color-yellow)';
      case 'black':
        return 'var(--button-bg-color-black)';
      case 'white':
        return 'var(--button-bg-color-white)';
    }
  }};

  border: ${({ color = defaultColor }: ButtonProps) => {
    switch (color) {
      case 'grey':
        return 'var(--button-border-color-grey)';
      case 'blue':
        return 'var(--button-border-color-blue)';
      case 'blue-flat':
        return 'var(--button-border-color-blue-flat)';
      case 'dark':
        return 'var(--button-border-color-dark)';
      case 'green':
        return 'var(--button-border-color-green)';
      case 'pink':
        return 'var(--button-border-color-pink)';
      case 'dark-pink':
        return 'var(--button-border-color-dark-pink)';
      case 'yellow':
        return 'var(--button-border-color-yellow)';
      case 'black':
        return 'var(--button-border-color-black)';
      case 'white':
        return 'var(--button-border-color-white)';
    }
  }};

  &:hover {
    background-color: ${({ color = defaultColor }: ButtonProps) => {
      switch (color) {
        case 'grey':
          return 'var(--button-bg-color-hover-grey)';
        case 'blue':
          return 'var(--button-bg-color-hover-blue)';
        case 'blue-flat':
          return 'var(--button-bg-color-hover-blue-flat)';
        case 'dark':
          return 'var(--button-bg-color-hover-dark)';
        case 'green':
          return 'var(--button-bg-color-hover-green)';
        case 'pink':
          return 'var(--button-bg-color-hover-pink)';
        case 'dark-pink':
          return 'var(--button-bg-color-hover-dark-pink)';
        case 'yellow':
          return 'var(--button-bg-color-hover-yellow)';
        case 'black':
          return 'var(--button-bg-color-hover-black)';
        case 'white':
          return 'var(--button-bg-color-hover-white)';
      }
    }};

    border-color: ${({ color = defaultColor }: ButtonProps) => {
      switch (color) {
        case 'grey':
          return 'var(--button-border-color-hover-grey)';
        case 'blue':
          return 'var(--button-border-color-hover-blue)';
        case 'blue-flat':
          return 'var(--button-border-color-hover-blue-flat)';
        case 'dark':
          return 'var(--button-border-color-hover-dark)';
        case 'green':
          return 'var(--button-border-color-hover-green)';
        case 'pink':
          return 'var(--button-border-color-hover-pink)';
        case 'dark-pink':
          return 'var(--button-border-color-hover-dark-pink)';
        case 'yellow':
          return 'var(--button-border-color-hover-yellow)';
        case 'black':
          return 'var(--button-border-color-hover-black)';
        case 'white':
          return 'var(--button-border-color-hover-white)';
      }
    }};
  }

  & svg {
    color: ${({ color = defaultColor }: ButtonProps) => {
      switch (color) {
        case 'grey':
          return 'var(--button-svg-color-grey)';
        case 'blue':
          return 'var(--button-svg-color-blue)';
        case 'blue-flat':
          return 'var(--button-svg-color-blue-flat)';
        case 'dark':
          return 'var(--button-svg-color-dark)';
        case 'green':
          return 'var(--button-svg-color-green)';
        case 'pink':
          return 'var(--button-svg-color-pink)';
        case 'dark-pink':
          return 'var(--button-svg-color-dark-pink)';
        case 'yellow':
          return 'var(--button-svg-color-yellow)';
        case 'black':
          return 'var(--button-svg-color-black)';
        case 'white':
          return 'var(--button-svg-color-white)';
      }
    }};
  }
`;

export default Button;
