import React, { useState } from 'react';
import './AnnouncementBanner.scss';
import { Flip } from '../Flip/Flip';
import { DarkTooltip } from '../Tooltip';

interface Props {
  announcement: React.ReactNode;
  announcementClosed: React.ReactNode;
  button: React.ReactNode;
  countdown?: string;
  showButtonWhenClosed?: boolean;
  tooltip?: React.ReactNode;
  onClosed?: () => void;
}

export const AnnouncementBanner: React.FC<Props> = ({
  announcement,
  announcementClosed,
  button,
  countdown,
  tooltip,
  showButtonWhenClosed,
  onClosed,
}) => {
  const [countdownCompleted, setCountdownCompleted] = useState(false);

  return tooltip ? (
    <DarkTooltip title={tooltip} arrow>
      <div
        className={`AnnouncementBanner ${countdownCompleted ? 'AnnouncementClosed' : ''} ${
          showButtonWhenClosed ? 'AnnouncementShowButtonWhenClosed' : ''
        }`}
      >
        {countdown && !countdownCompleted ? (
          <div className="AnnouncementContentWrapper">
            <div className="AnnouncementContent">{announcement}</div>
            <Flip
              value={countdown}
              onTimesUp={() => {
                setCountdownCompleted(true);
                onClosed && onClosed();
              }}
            />
          </div>
        ) : (
          <div className="AnnouncementContent">{countdownCompleted ? announcementClosed : announcement}</div>
        )}
        {((countdownCompleted && showButtonWhenClosed) || (!countdownCompleted && !showButtonWhenClosed)) && (
          <div className="AnnouncementButtons">{button}</div>
        )}
      </div>
    </DarkTooltip>
  ) : (
    <div
      className={`AnnouncementBanner ${countdownCompleted ? 'AnnouncementClosed' : ''} ${
        showButtonWhenClosed ? 'AnnouncementShowButtonWhenClosed' : ''
      }`}
    >
      {countdown && !countdownCompleted ? (
        <div className="AnnouncementContentWrapper">
          <div className="AnnouncementContent">{announcement}</div>
          <Flip
            value={countdown}
            onTimesUp={() => {
              setCountdownCompleted(true);
              onClosed && onClosed();
            }}
          />
        </div>
      ) : (
        <div className="AnnouncementContent">{countdownCompleted ? announcementClosed : announcement}</div>
      )}
      {((countdownCompleted && showButtonWhenClosed) || (!countdownCompleted && !showButtonWhenClosed)) && (
        <div className="AnnouncementButtons">{button}</div>
      )}
    </div>
  );
};
