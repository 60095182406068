import { Store } from '../index';

export interface AlertState {
  open: boolean;
  message?: string;
  autoHideDurationMs: number;
}

export const initialState: AlertState = {
  open: false,
  autoHideDurationMs: 6000,
};

export const selectAlert: (store: Store) => AlertState = (store: Store) => store.alert;

export default initialState;
