import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../state/modal/actions';
import {
  Backdrop,
  CircularProgress,
  Divider as MuiDivider,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemIconProps,
  Modal as MuiModal,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { DividerProps } from '@mui/material/Divider/Divider';
import { JazzIcon } from '../JazzIcon';
import { selectModal } from '../../state/modal/state';
import { selectChainId, selectTransactions } from '../../state/wallet/state';
import { toEtherscanUrl } from '../../utils/thirdPartyUrls';
import { minifyAddress } from '../../utils/strings';
import { Row, Column } from '../RowColumn';
import { isPendingTx, TransactionStatus } from '../../utils/transactions';
import './index.sass';

export default function Modal(): ReactElement {
  const chainId = useSelector(selectChainId);
  const modalState = useSelector(selectModal);
  const transactions = useSelector(selectTransactions);
  const dispatch = useDispatch();

  // TODO: for debugging
  // const transactions = [
  //   {
  //     status: TransactionStatus.SUCCESS,
  //     hash: '0x18154fe293a992d3b060bdd0d489bd459eb22d13b92f4c53eba551edd5f66207',
  //   },
  //   {
  //     status: TransactionStatus.PROCESSING,
  //     hash: '0xc7347f80b6a078c0b26929a475fde250291377ebbe7aa7f76eaa2515bbd210ee',
  //   },
  //   {
  //     status: TransactionStatus.FAILURE,
  //     hash: '0xc1245f80b6a078c0b26929a475fde250291377ebbe7aa7f76eaa2515bbd245df',
  //   },
  // ]

  function handleClick(tx: any) {
    window.open(toEtherscanUrl(chainId, tx.hash), '_blank');
  }

  return (
    <MuiModal
      className="modal"
      open={modalState.open}
      onClose={() => dispatch(closeModal())}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
    >
      <Fade in={modalState.open}>
        <div className="modal-wrapper">
          {/* hack forwardRef warning */}
          <Root>
            <Header onClose={() => dispatch(closeModal())}>
              {transactions.length === 0 ? 'Your transactions will appear here...' : 'Recent transactions'}
            </Header>
            {transactions.length > 0 && (
              <>
                <Divider />
                <List>
                  {transactions
                    .slice()
                    .reverse()
                    .map((tx) => (
                      <ListItem button key={tx.hash} onClick={() => handleClick(tx)} className="modal-list-item">
                        <ListIcon status={tx.status} />
                        <span>{minifyAddress(tx.hash)}</span>
                        <JazzIcon address={tx.hash} />
                      </ListItem>
                    ))}
                </List>
              </>
            )}
          </Root>
        </div>
      </Fade>
    </MuiModal>
  );
}

const Root = styled(Column)({
  color: 'white',
  padding: '16px',
  minWidth: '25vw',
  boxShadow: '0 0 10px 5px rgb(24 49 107 / 30%)',
  borderRadius: '8px',
  backgroundColor: 'rgb(35, 35, 35)',
});

interface HeaderProps {
  onClose: () => void;
  children: Element[] | string;
}

const Header = styled(({ onClose, ...props }: HeaderProps) => (
  <Row {...props}>
    <span>{props.children}</span>
    <CloseIcon onClick={onClose} />
  </Row>
))({
  position: 'relative',
  justifyContent: 'center',
  '& > svg': {
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    cursor: 'pointer',
    transition: 'color 150ms',
    '&:hover': {
      color: 'rgb(191 191 191)',
    },
  },
});

const Divider = styled((props: DividerProps) => <MuiDivider {...props} variant="middle" />)({
  marginTop: '8px',
  backgroundColor: 'rgb(189 189 189 / 12%)',
});

interface ListIconProps extends ListItemIconProps {
  status: TransactionStatus;
}

const ListIcon = styled(({ status, ...props }: ListIconProps) => (
  <ListItemIcon {...props}>
    {isPendingTx(status) ? (
      <CircularProgress size={24} />
    ) : status === TransactionStatus.FAILURE ? (
      <CancelIcon htmlColor="#d50000" />
    ) : (
      <DoneIcon htmlColor="#00c853" />
    )}
  </ListItemIcon>
))({});
