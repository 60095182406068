import { createReducer } from '@reduxjs/toolkit';
import initialState, { WalletStatus } from './state';
import {
  addTransaction,
  addTransactions,
  clearWalletStatus,
  updateAddress,
  updatePaperAddress,
  updateBalance,
  updateNftBalance,
  updateChainId,
  updateTransactions,
  updateWalletStatus,
  updateBlockNumber,
  updateRequestToConnect,
  updateNftSymbol,
} from './actions';
import { Transaction, TransactionBatch, TransactionStatus } from '../../utils/transactions';

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateBalance, (state, action) => {
      state.balance = action.payload;
      if (state.status === WalletStatus.NOT_LOADED) {
        state.status = WalletStatus.LOADED;
      }
    })
    .addCase(updateNftBalance, (state, action) => {
      state.nftBalance = action.payload;
      if (state.status === WalletStatus.NOT_LOADED) {
        state.status = WalletStatus.LOADED;
      }
    })
    .addCase(updateNftSymbol, (state, action) => {
      state.nftSymbol = action.payload;
    })
    .addCase(updateWalletStatus, (state, action) => {
      state.status = action.payload;
    })
    .addCase(clearWalletStatus, (state, action) => {
      if (state.status === action.payload) {
        state.status = WalletStatus.LOADED;
      }
    })
    .addCase(updateAddress, (state, action) => {
      state.address = action.payload;
    })
    .addCase(updatePaperAddress, (state, action) => {
      state.paperAddress = action.payload;
      localStorage.setItem('paper-wallet', action.payload);
    })
    .addCase(updateChainId, (state, action) => {
      state.chainId = action.payload;
    })
    .addCase(addTransaction, (state, action) => {
      const tx = action.payload;
      state.transactions.push({
        ...tx,
        status: TransactionStatus.NEW,
      });
    })
    .addCase(addTransactions, (state, action) => {
      state.transactions.push(
        ...action.payload.map((tx) => ({
          ...tx,
          status: TransactionStatus.NEW,
        }))
      );
    })
    .addCase(updateTransactions, (state, action) => {
      let statusFn = (tx: Transaction) => tx.status;
      let transactions;
      if ((action.payload as TransactionBatch).transactions) {
        const payload = action.payload as TransactionBatch;
        transactions = payload.transactions;
        statusFn = payload.status;
      } else {
        transactions = action.payload as Array<Transaction>;
      }

      const map = transactions.reduce((map, tx) => map.set(tx.hash, tx), new Map<string, Transaction>());
      state.transactions = state.transactions.map((tx) => {
        if (map.has(tx.hash)) {
          const newTx = map.get(tx.hash) as Transaction;
          return {
            ...newTx,
            status: statusFn(newTx),
          };
        } else {
          return tx;
        }
      });
    })
    .addCase(updateBlockNumber, (state, action) => {
      state.blockNumber = action.payload;
    })
    .addCase(updateRequestToConnect, (state, action) => {
      state.requestToConnect = action.payload;
    })
);
