import { createReducer } from '@reduxjs/toolkit';
import initialState from './state';
import { updateAppStage, updateAppStatus } from './actions';

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateAppStage, (state, action) => {
      state.stage = action.payload;
    })
    .addCase(updateAppStatus, (state, action) => {
      state.status = action.payload;
    })
);
