import { createAction } from '@reduxjs/toolkit';
import { ModalType } from './state';

export const updateModal = createAction<{
  open: boolean;
  type?: ModalType;
}>('modal/updateState');

export const openModal = createAction<ModalType>('modal/open');
export const closeModal = createAction<void>('modal/close');
