import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import React from 'react';
import './footer.scss';
import { Contact } from '../../../components/Contact/contact';
import fineLine from '../../../assets/fine_line.png';
import { SocialLinks } from '../../../components/Social/social_links';
import { LottieOptions, useLottie } from 'lottie-react';
import starAnimation from '../../../assets/lottie/stars_footer_web.json';

export const Footer: React.FC = () => {
  const lottieOptions: LottieOptions = {
    animationData: starAnimation,
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMaxYMax',
    },
    className: 'starAnimation',
  };
  const { View } = useLottie(lottieOptions);

  return (
    <footer id="footer">
      {View}
      <Contact />
      <div className="LinkList">
        <div>
          <div className="heading">Join the community</div>
          <SocialLinks />
        </div>
        <div className="heading">
          <Link component={RouterLink} to="/tos" color="inherit" target="_blank" rel="noreferrer">
            Terms of Service
          </Link>
        </div>
        <div>
          <div className="heading">Site designed by</div>
          <a href="https://fine-line.co" target="_blank" rel="noreferrer">
            <img src={fineLine} alt="Fine Line" style={{ width: '24px', height: '24px' }} />
          </a>
        </div>
      </div>
    </footer>
  );
};
