import { createAction } from '@reduxjs/toolkit';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { WalletStatus } from './state';
import { Transaction, TransactionBatch } from '../../utils/transactions';
import { ChainId } from '../../utils/chainId';

export const updateBalance = createAction<string>('wallet/updateBalance');
export const updateNftBalance = createAction<string>('wallet/updateNftBalance');
export const updateNftSymbol = createAction<string>('wallet/updateNftSymbol');
export const updateWalletStatus = createAction<WalletStatus>('wallet/updateStatus');
export const clearWalletStatus = createAction<WalletStatus>('wallet/clearStatus');
export const updateAddress = createAction<string>('wallet/updateAddress');
export const updatePaperAddress = createAction<string>('wallet/updatePaperAddress');
export const updateChainId = createAction<ChainId>('wallet/updateChainId');
export const addTransaction = createAction<TransactionResponse>('wallet/addTransaction');
export const addTransactions = createAction<Array<TransactionResponse>>('wallet/addTransactions');
export const updateTransactions = createAction<TransactionBatch | Array<Transaction>>('wallet/updateTransactions');
export const updateBlockNumber = createAction<number>('wallet/updateBlockNumber');
export const updateRequestToConnect = createAction<boolean>('wallet/updateRequestToConnect');
