import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import logo from '../../../assets/logo.png';
import logoNoText from '../../../assets/logo_no_text.png';
import { useMediaQuery } from '@mui/material';
import {
  // DISCORD_URL,
  SHOW_LINK_TO_REGISTRATION,
  SHOW_LINK_TO_EVENT,
  SHOW_ANNOUNCEMENT,
  SHOW_LINK_TO_DISCORD_AT_TOP,
  ANNOUNCEMENT_COUNTDOWN_DATE,
  isInSaleStage,
  DISCORD_URL,
} from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppStage, selectAppStage } from '../../../state/app/state';
import './header.scss';
import { SocialLinks } from '../../../components/Social/social_links';
const Wallet = React.lazy(() => import('../../../components/Wallet'));
import Button from '../../../components/Button';
import { AnnouncementBanner } from '../../../components/AnnouncementBanner/AnnouncementBanner';
import { HashLink } from 'react-router-hash-link';
import { Contract } from '@ethersproject/contracts';
import { showAlert } from '../../../state/alert/actions';
import discordCTA from '../../../assets/discord_cta.png';
// import { updateAppStage } from '../../../state/app/actions';

interface HeaderProps {
  onWalletConnected: (nftContract: Contract | undefined, auctionContract: Contract | undefined) => void;
}

export const Header: React.FC<HeaderProps> = ({ onWalletConnected }) => {
  const smallScreen = useMediaQuery(`(max-width: 1170px)`);
  // const mobileScreen = useMediaQuery(`(max-width: 510px)`);
  const appStage = useSelector(selectAppStage);
  const { pathname } = useLocation();
  const inSalesPage = pathname.includes('sale') || pathname.includes('register');
  const showAnnouncement = SHOW_ANNOUNCEMENT && !inSalesPage;
  const dispatch = useDispatch();

  const [isSticky, setSticky] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (menuRef.current) {
        const sticky = menuRef.current.getBoundingClientRect().top - (showAnnouncement ? 48 : 0) <= 0;
        if (isSticky !== sticky) setSticky(sticky);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [isSticky, showAnnouncement]);

  const wallet = useMemo(
    () => (
      <Wallet
        buttonText="Connect Wallet"
        onConnectionChange={(connected, provider, autoLoad, address, nftContract, auctionContract) => {
          if (connected) {
            onWalletConnected(nftContract, auctionContract);
          }

          if (connected && !autoLoad && window.location.pathname != '/sale') {
            console.log('Redirecting');
            window.location.href = './sale';
          }
        }}
        onWrongChain={(chainId) => {
          dispatch(showAlert('Wrong Chain selected. Please change the selected chain in your Wallet.'));
        }}
      ></Wallet>
    ),
    [onWalletConnected, dispatch]
  );
  useEffect(() => {
    if (SHOW_LINK_TO_EVENT) {
      (window as any).EBWidgets.createWidget({
        widgetType: 'checkout',
        eventId: '278398857477',
        modal: true,
        modalTriggerElementId: 'eventbrite-widget-modal-trigger-278398857477',
      });
    }
  }, []);

  return (
    <header className={`${smallScreen ? 'smallScreen' : ''} ${showAnnouncement && 'announcement'}`}>
      <div className="topSection">
        <div className="topSectionWrapper">
          <HashLink smooth={true} to="/" className="AppLogoLink">
            <img className="app-logo" src={logo} alt="Pride Icon logo" />
          </HashLink>
          <h2 className="tagline">The Largest LGBTQ+ NFT Community In The World</h2>
        </div>
        {SHOW_LINK_TO_EVENT ? (
          <Button id="eventbrite-widget-modal-trigger-278398857477" color="black" className="buttonRsvp">
            RSVP To Launch Event
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="menuContainer" ref={menuRef}>
        {showAnnouncement && (
          <AnnouncementBanner
            announcement={
              <span>
                The public{' '}
                <HashLink className="link-light" smooth={true} to="/#lookbook">
                  mint
                </HashLink>{' '}
                opens in
              </span>
            }
            announcementClosed={'Public mint is now open!'}
            tooltip={
              <p>
                Minting of 2000 Prime Pi NFTs. See{' '}
                <HashLink className="link-dark" smooth={true} to="/#lookbook">
                  our collection
                </HashLink>{' '}
                and{' '}
                <HashLink className="link-dark" smooth={true} to="/#memberships">
                  membership tiers
                </HashLink>{' '}
                for more information.
              </p>
            }
            showButtonWhenClosed
            // onClosed={() => {
            //   dispatch(updateAppStage(AppStage.SALE));
            // }}
            button={
              <HashLink smooth={true} to="/sale">
                <Button color="white">{'Go Mint'}</Button>
              </HashLink>
            }
            countdown={ANNOUNCEMENT_COUNTDOWN_DATE}
          />
        )}
        <section className={`menu${isSticky || smallScreen ? ' sticky' : ''} ${showAnnouncement && 'announcement'}`}>
          <div className="MenuSide Left">
            <a href="/" className="AppLogoLink">
              <img className="app-logo-sticky" src={logoNoText} alt="Pride Icon logo" />
            </a>
          </div>
          {!smallScreen && <>{menuItems()}</>}
          <div className="MenuSide">
            {smallScreen ? (
              isInSaleStage(appStage) &&
              !inSalesPage && (
                <HashLink smooth={true} to="/sale" color="inherit">
                  <Button className={`header-sales-button ${smallScreen ? 'smallscreen' : ''}`}>Sales Zone</Button>
                </HashLink>
              )
            ) : (
              <>
                {SHOW_LINK_TO_REGISTRATION && (
                  <MenuLink text={'Registration'} link="/register" color={'white'} className="rainbowLink"></MenuLink>
                )}
                {isInSaleStage(appStage) ? (
                  inSalesPage ? (
                    <MenuLink text={'Sales Zone'} link="/sale" color={'white'} className="rainbowLink"></MenuLink>
                  ) : (
                    <HashLink smooth={true} to="/sale" color="inherit">
                      <Button className="header-sales-button">Sales Zone</Button>
                    </HashLink>
                  )
                ) : (
                  <></>
                )}
              </>
            )}
            {inSalesPage && wallet}
            {SHOW_LINK_TO_DISCORD_AT_TOP && (
              <a href={DISCORD_URL} target="_blank" rel="noreferrer">
                <img src={discordCTA} className="cta-link" alt="Join our Discord" />
              </a>
            )}

            {smallScreen && <MenuHamburger showAnnouncement={showAnnouncement} appStage={appStage} wallet={wallet} />}
          </div>
        </section>
      </div>
    </header>
  );
};

interface Props {
  link: string;
  text: string;
  color: string;
  className?: string;
  onClick?: () => void;
}

export const MenuLink: React.FC<Props> = ({ link, text, onClick, className }) => {
  return (
    <div className="MenuItem">
      <HashLink smooth={true} onClick={onClick} to={link} color="inherit" className={`${className || ''}`}>
        <span className="LinkText">{text}</span>
      </HashLink>
    </div>
  );
};

const menuItems = (onClick?: () => void) => (
  <>
    <MenuLink text={'Vision'} link="/#vision" color="#e6a7f7" onClick={onClick}></MenuLink>
    <MenuLink text={'The Collection'} link="/#lookbook" color="#f1fc74" onClick={onClick}></MenuLink>
    <MenuLink text={'Pi Membership'} link="/#memberships" color="#92f9dd" onClick={onClick}></MenuLink>
    <MenuLink text={'Roadmap'} link="/#roadmap" color="#f3c546" onClick={onClick}></MenuLink>
    <MenuLink text={'Team'} link="/#team" color="#ea5028" onClick={onClick}></MenuLink>
    <MenuLink text={'FAQs'} link="/#faq" color="#96d6fd" onClick={onClick}></MenuLink>
  </>
);

interface HamburgerMenuProps {
  showAnnouncement: boolean;
  appStage: AppStage;
  wallet?: React.ReactNode;
}

const MenuHamburger: React.FC<HamburgerMenuProps> = ({ showAnnouncement, appStage, wallet }) => {
  const menuInputRef = useRef<HTMLInputElement>(null);
  const closeMenu = useCallback(() => {
    if (menuInputRef.current) {
      menuInputRef.current.checked = false;
    }
    // Reset in case of resize while menu open
    document.body.style.overflow = '';
  }, [menuInputRef]);

  const menuOpenChange = useCallback((open) => {
    document.body.style.overflow = open ? 'hidden' : '';
  }, []);

  useEffect(() => {
    return () => {
      closeMenu();
    };
  });

  return (
    <nav role="navigation" className="hamburgerMenu">
      <div id="menuToggle">
        <input type="checkbox" ref={menuInputRef} onChange={(event) => menuOpenChange(event.target.checked)} />
        {closeButton(showAnnouncement)}
        {menuButton}
        <div
          id="menu"
          className={showAnnouncement ? 'announcement' : ''}
          onScroll={(event) => {
            event.preventDefault();
          }}
        >
          {SHOW_LINK_TO_REGISTRATION ? (
            <>
              {/* <div className="mobileWallet">{wallet}</div> */}
              <MenuLink
                text={'Registration'}
                link="/register"
                onClick={closeMenu}
                color={'white'}
                className="rainbowLink"
              ></MenuLink>
              <br />
            </>
          ) : (
            <></>
          )}
          {isInSaleStage(appStage) ? (
            <>
              <MenuLink
                text={'Sales Zone'}
                link="/sale"
                onClick={closeMenu}
                color={'white'}
                className="rainbowLink"
              ></MenuLink>
              <br />
            </>
          ) : (
            <></>
          )}
          {menuItems(closeMenu)}
          <div className="menuFooter">
            <SocialLinks />
            <div className="Disclaimers">
              <span>© Pride Icons</span>
              <span>All rights reserved</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const menuButton = (
  <>
    <svg
      className="hamburger"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="33" height="33" rx="5" fill="black" />
      <line x1="8" y1="12" x2="25" y2="12" stroke="white" strokeWidth="2" />
      <line x1="8" y1="16.5" x2="25" y2="16.5" stroke="white" strokeWidth="2" />
      <line x1="8" y1="21" x2="25" y2="21" stroke="white" strokeWidth="2" />
    </svg>
  </>
);

const closeButton = (showAnnouncement: boolean) => (
  <>
    <svg
      className={`hamburgerClose ${showAnnouncement && 'announcement'}`}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="33" height="33" rx="5" fill="black" />
      <path d="M10 23L23.9998 9.00021" stroke="white" strokeWidth="2" />
      <path d="M24 23.001L10.0002 9.00119" stroke="white" strokeWidth="2" />
    </svg>
  </>
);
