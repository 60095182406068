import { Link } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import Button from '../Button';
import './contact.scss';
import CheckIcon from '@mui/icons-material/Check';
import { BACKEND_HOST } from '../../utils/constants';

export const Contact: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = useCallback(async () => {
    setCanSend((inputRef.current?.value || '') != '');
  }, [inputRef]);

  const submit = useCallback(async () => {
    console.log('Submitting');
    window.gtag('event', 'emailSignup');
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: inputRef.current?.value }),
    };
    const response = await fetch(BACKEND_HOST + '/subscribe', requestOptions);
    const result = await response.json();
    console.log(result);
    setLoading(false);
    setSubmitted(true);
  }, [inputRef]);
  return (
    <div className="ContactForm">
      <span className="heading">Contact</span>
      <Link href="mailto:info@prideicons.io" color="inherit">
        info@prideicons.io
      </Link>
      <p>
        {!submitted && (
          <>
            Join our mailing list
            <span className="emailInput">
              <input
                type="email"
                id="email"
                name="email"
                size={10}
                ref={inputRef}
                onChange={onChange}
                placeholder="Your email adress"
              />
              <Button loading={loading} color="white" disabled={!canSend} onClick={submit}>
                <span className="signupText">Sign up</span>
                <CheckIcon className="signupIcon" />
              </Button>
            </span>
          </>
        )}
        {submitted && <>Thank you for signing up!</>}
      </p>
    </div>
  );
};
