/** Reduces an Address to first and last 4 hex values for brevity. */
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { BigNumber } from 'ethers';
import { ChainId } from './chainId';

export function minifyAddress(address: string): string {
  if (!address) {
    return '';
  }
  return address.replace(/^(.{6}).+(.{4})$/, '$1...$2');
}

export function formatWeiHex(weiHex: string, precision = 3): string {
  return formatNumber(BigNumber.from(weiHex), precision);
}

export function formatNumber(number?: BigNumber | number, precision = 4, unit = 18): string {
  if (number === undefined) return '';
  if (typeof number === 'number') return number.toPrecision(precision);
  return Number(formatUnits(number, unit)).toPrecision(precision);
}

export function formatEth(number: BigNumber, precision = 4): string {
  return Number(formatEther(number)).toPrecision(precision);
}

export function isNumber(value: string | number | undefined): boolean {
  return value != null && value !== '' && !isNaN(Number(value.toString()));
}

export function toSentenceCase(str: string): string {
  return str
    .toLowerCase()
    .replace(/[a-z]/i, function (letter) {
      return letter.toUpperCase();
    })
    .trim();
}

export function toNetworkName(chainId: ChainId): string {
  switch (chainId) {
    case ChainId.UNSPECIFIED:
      return '';

    case ChainId.MAINNET:
      return 'Mainnet';

    case ChainId.ROPSTEN:
      return 'Ropsten';

    case ChainId.RINKEBY:
      return 'Rinkeby';

    case ChainId.GOERLI:
      return 'Goerli';

    case ChainId.KOVAN:
      return 'Kovan';

    default:
      console.error('Unknown chain id ' + chainId);
      return 'Unknown Chain';
  }
}
