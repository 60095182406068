export enum AppStage {
  UNSPECIFIED = 'UNSPECIFIED',
  COMING_SOON = 'COMING_SOON',
  REGISTRATION = 'REGISTRATION',
  AUCTION = 'AUCTION',
  EARLYBIRDS = 'EARLYBIRDS',
  PRESALE = 'PRESALE',
  SALE = 'SALE',
  HIBERNATING = 'HIBERNATING',
}

export default AppStage;
