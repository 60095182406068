import styled from '@emotion/styled';
import { animated } from 'react-spring';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AnimatedColumn = animated(Column);

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const AnimatedRow = animated(Row);

export interface RowColumnProps {
  smallScreen?: boolean;
  reverse?: boolean;
}

export const RowColumn = styled.div<RowColumnProps>`
  display: flex;
  flex-direction: ${({ smallScreen, reverse }: RowColumnProps) =>
    smallScreen ? (reverse ? 'column-reverse' : 'column') : 'row'};
`;
export const AnimatedRowColumn = animated(RowColumn);
