import { Store } from '../index';
import AppStage from './AppStage';
import { APP_STAGE } from '../../utils/constants';

export * from './AppStage';
export interface AppState {
  stage: AppStage;
  status: AppStatus;
}

export enum AppStatus {
  GOOD,
  NETWORK_ISSUES,
}

export const initialState: AppState = {
  stage: APP_STAGE,
  status: AppStatus.GOOD,
};

export const selectAppStage: (store: Store) => AppStage = (store: Store) => store.app.stage;

export const selectAppStatus: (store: Store) => AppStatus = (store: Store) => store.app.status;

export default initialState;
