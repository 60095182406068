import React, { useRef, useEffect, useState } from "react";
import { useMediaQuery } from '@mui/material';
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";
import './Flip.scss';

export const Flip = ({ value, onTimesUp }) => {
  const mobileScreen = useMediaQuery(`(max-width: 620px)`);
  const divRef = useRef();
  const tickRef = useRef();

  const [tickValue, setTickValue] = useState(value);
  const mounted = useRef(false);

  useEffect(() => {
      mounted.current = true;

      return () => {
          mounted.current = false;
      };
  }, []);

  // Make the Tick instance and store it in the refs
  useEffect(() => {
    const didInit = tick => {
      tickRef.current = tick;
      // Delay the display because on Safari it needs more time to load
      // otherwise it shows the full date
      setTimeout(() => {
        if (divRef.current) {
          divRef.current.style.display = "block";
        }
      }, 350);
    };

    const currDiv = divRef.current;
    const tickValue = tickRef.current;
    Tick.DOM.create(currDiv, {
      value,
      didInit
    });
    return () => Tick.DOM.destroy(tickValue);
  }, [value]);

  // Start the Tick.down process
  useEffect(() => {
    const counter = Tick.count.down(value, {
      format: ["d", "h", "m", "s"]
    });

    // When the counter updates, update React's state value
    counter.onupdate = function(value) {
      if (mounted.current) {
        setTickValue(value);
      }
    };

    counter.onended = function() {
      if (onTimesUp) {
        onTimesUp();
      }
    }

    return () => {
      counter.timer.stop();
    };
  }, [value]);

  // When the tickValue is updated, update the Tick.DOM element
  useEffect(() => {
    if (tickRef.current) {
      tickRef.current.value = tickValue;
    }
  }, [tickValue]);

  return (
    <div ref={divRef} className="tick" style={{display: 'none'}}>

      <div data-layout="horizontal fit" data-value-mapping="indexes">

        <span className="tick-group">
            <span data-view="flip"></span>
            <span className="tick-text-inline">{mobileScreen ? "d" : tickValue[0] === 1 ? "day" : "days"}</span>
        </span>

        <span className="tick-group">
            <span data-repeat="true" data-transform="pad(00) -> split">
                <span data-view="flip"></span>
            </span>
            <span className="tick-text-inline">{mobileScreen ? "h" : tickValue[1] === 1 ? "hour" : "hours"}</span>
        </span>

        <span className="tick-group">
            <span data-repeat="true" data-transform="pad(00) -> split">
                <span data-view="flip"></span>
            </span>
            <span className="tick-text-inline">{mobileScreen ? "m" : tickValue[2] === 1 ? "minute" : "minutes"}</span>
        </span>

        <span className="tick-group">
            <span data-repeat="true" data-transform="pad(00) -> split">
                <span data-view="flip"></span>
            </span>
            <span className="tick-text-inline">{mobileScreen ? "s" : tickValue[3] === 1 ? "second" : "seconds"}</span>
        </span>

      </div>
  </div>
  );
};

export default Flip;