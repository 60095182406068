import { Store } from '../index';
import { Transaction } from '../../utils/transactions';
import { ChainId } from '../../utils/chainId';

export enum WalletStatus {
  NOT_LOADED,
  LOADED,
  PENDING_TRANSACTION,
}

export interface WalletState {
  status: WalletStatus;
  balance?: string;
  nftBalance?: string;
  nftSymbol?: string;
  address?: string;
  paperAddress?: string;
  chainId: ChainId;
  transactions: Array<Transaction>;
  blockNumber?: number;
  requestToConnect?: boolean;
}

export const initialState: WalletState = {
  status: WalletStatus.NOT_LOADED,
  chainId: ChainId.UNSPECIFIED,
  paperAddress: localStorage.getItem('paper-wallet') ?? '',
  transactions: [],
};

export const selectWalletStatus: (store: Store) => WalletStatus = (store: Store) => store.wallet.status;

export const selectBalance: (store: Store) => [string | undefined, WalletStatus] = (store: Store) => [
  store.wallet.balance,
  store.wallet.status,
];

export const selectNftBalance: (store: Store) => [string | undefined, WalletStatus] = (store: Store) => [
  store.wallet.nftBalance,
  store.wallet.status,
];

export const selectNftSymbol: (store: Store) => string | undefined = (store: Store) => store.wallet.nftSymbol;

export const selectAddress: (store: Store) => string | undefined = (store: Store) => store.wallet.address;

export const selectPaperAddress: (store: Store) => string | undefined = (store: Store) => store.wallet.paperAddress;

export const selectChainId: (store: Store) => ChainId = (store: Store) => store.wallet.chainId;

export const selectTransactions: (store: Store) => Array<Transaction> = (store: Store) => store.wallet.transactions;

export const selectBlockNumber: (store: Store) => number | undefined = (store: Store) => store.wallet.blockNumber;

export const selectRequestToConnect: (store: Store) => boolean | undefined = (store: Store) =>
  store.wallet.requestToConnect;

export default initialState;
