import { ReactElement } from 'react';
import './index.scss';

export default function Provenance(): ReactElement {
  return (
    <div className="provenance">
      <h1>Provenance</h1>
      <h3>Important information</h3>
      <p>
        Each Pride Icons token ID is assigned to an artwork image from the initial sequence with this formula: (tokenId
        + startingIndex) % 10000 - Initial Sequence Index
      </p>
      <p>
        <b>Relevant Information:</b>
      </p>
    </div>
  );
}
