import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import wallet from './wallet/reducer';
import modal from './modal/reducer';
import alert from './alert/reducer';
import app from './app/reducer';
import { WalletState } from './wallet/state';
import { ModalState } from './modal/state';
import { AlertState } from './alert/state';
import { AppState } from './app/state';
import { addTransaction, addTransactions, updateTransactions } from './wallet/actions';

const store = configureStore({
  reducer: {
    wallet,
    modal,
    alert,
    app,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [addTransaction.type, addTransactions.type, updateTransactions.type],
      ignoredPaths: ['wallet.transactions'],
    },
  }),
});

export interface Store {
  wallet: WalletState;
  modal: ModalState;
  alert: AlertState;
  app: AppState;
}

export default store;
