const IS_PROD = true;
import ChainId from './chainId';
import AppStage from '../state/app/AppStage';

export const BACKEND_HOST_PROD = 'https://api.prideicons.io';
export const BACKEND_HOST_DEV = 'https://prideicons-dev.herokuapp.com';
export const BACKEND_HOST = IS_PROD ? BACKEND_HOST_PROD : BACKEND_HOST_DEV;

export const GOOGLE_AUTH_DOMAIN = IS_PROD ? 'prideicons.io' : 'pride-icons-staging.web.app';

export const APP_STAGE = AppStage.HIBERNATING;
export const isInSaleStage = (appStage: AppStage): boolean =>
  [AppStage.AUCTION, AppStage.EARLYBIRDS, AppStage.PRESALE, AppStage.SALE].includes(appStage);
export const isInMintSaleStage = (appStage: AppStage): boolean =>
  [AppStage.EARLYBIRDS, AppStage.PRESALE, AppStage.SALE].includes(appStage);
export const isHibernating = (appStage: AppStage): boolean => appStage === AppStage.HIBERNATING;
export const SUPPORTED_CHAINS = IS_PROD ? [ChainId.MAINNET] : [ChainId.MAINNET, ChainId.RINKEBY];
export const SMALL_SCREEN_SIZE = 800;

export const DEFAULT_CHAIN_ID = IS_PROD ? ChainId.MAINNET : ChainId.RINKEBY;

export const MIN_BID_ADD = IS_PROD ? '0.01' : '0.001';
export const PAPER_MINT_GOLD_ID = IS_PROD
  ? '4ca27b50-fe51-4d62-a024-a4f1414df272'
  : '6b387cdf-8f1f-48ce-b000-3c12dff7d720';

export const SHOW_ANNOUNCEMENT = !isHibernating(APP_STAGE) && true;
export const SHOW_LINK_TO_DISCORD_AT_TOP = true;
export const ENABLE_LINK_TO_PAPA_REGISTRATION = false;
export const SHOW_LINK_TO_REGISTRATION = false;
export const SHOW_LINK_TO_EVENT = false;
export const SHOW_WALLET_BALANCE = false;
export const SHOW_ALT_REGISTRATION = true;
export const SHOW_LOGIN_IN_AUCTION = false;
export const HIDE_SILVER_MINTING = true;

export const ANNOUNCEMENT_COUNTDOWN_DATE = '2022-06-14T12:00:00-04:00'; // ET

export const TWITTER_URL = 'https://twitter.com/PrideIconsNFT';
export const INSTAGRAM_URL = 'https://www.instagram.com/prideiconsnft/';
export const DISCORD_URL = 'https://discord.gg/5W655KQz7r';
export const TELEGRAM_URL = 'https://t.me/prideicons';

export const SHOW_WALLET = false;

export const PAGE_TITLE = 'Pride Icons - The largest LGBTQ+ NFT Collection';

export const REGISTRATION_EMAIL_COOKIE = 'registrationEmail';

export const GTAG_EVENTS = {
  CONNECTED_WALLET: 'connected_wallet',
  CONNECTED_EMAIL: 'connected_email',
  CONNECTED_GMAIL: 'connected_gmail',
  UPDATE_TWITTER: 'update_twitter',
  UPDATE_INSTAGRAM: 'update_instagram',
  UPDATE_DISCORD: 'update_discord',
  COMPLETED_REGISTRATION: 'completed_registration',
  PLACE_BID_ATTEMPT: 'place_bid_attempt',
  PLACE_BID_SUCCESS: 'place_bid_success',
  PLACE_BID_FAILURE_DENIED: 'place_bid_failure_user_denied',
  PLACE_BID_FAILURE_NO_FUNDS: 'place_bid_failure_insufficient_funds',
  PLACE_BID_FAILURE_UNKNOWN: 'place_bid_failure_unknown',
  PAPER_PLACE_BID_ATTEMPT: 'paper_place_bid_attempt',
  PAPER_PLACE_BID_SUCCESS: 'paper_place_bid_success',
  PAPER_PLACE_BID_FAILURE_DENIED: 'paper_place_bid_failure_user_denied',
  MINT_ATTEMPT: 'mint_attempt',
  MINT_SUCCESS: 'mint_success',
  PAPER_MINT_ATTEMPT: 'paper_mint_attempt',
  PAPER_MINT_SUCCESS: 'paper_mint_success',
  PAPER_MINT_FAILURE_DENIED: 'paper_mint_failure_user_denied',
};

export const GTAG_CATEGORIES = {
  REGISTRATION: 'registration',
  SALES: 'sales',
  WALLET: 'wallet',
  PAPER: 'paper',
};

export const NFT_URL_PREFIX = 'https://prideicons.mypinata.cloud/ipfs/Qma2npssDxKCPvDnwCxxPnvsVu9d7EuCvkSgTCu1ykEwre/';

export const ETHERSCAN_API_KEY = 'YVTJNCHH3545I9MXU1YJZGY24HAKGC1VWG';

export default {};
