import { ReactElement, useEffect, useRef } from 'react';
import Jazzicon from 'jazzicon';
import styled from '@emotion/styled';

interface JazzIconProps {
  address?: string;
}

export const JazzIcon = ({ address }: JazzIconProps): ReactElement => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (address && ref.current) {
      ref.current.innerHTML = '';
      ref.current.appendChild(Jazzicon(16, parseInt(address.slice(2, 10), 16)));
    }
  }, [address]);

  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
  return <Container ref={ref as any} />;
};

const Container = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 1.125rem;
  background-color: rgb(60, 62, 66);
`;

export default JazzIcon;
