import { lazy, ReactElement, Suspense, useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Contract } from '@ethersproject/contracts';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { closeAlert } from '../../state/alert/actions';
import { selectAlert } from '../../state/alert/state';
const Home = lazy(() => import('../Home'));
const Tos = lazy(() => import('../Tos'));
import Modal from '../../components/Modal';
import './index.scss';
import { Header } from './header/header';
import { Footer } from './footer/footer';
import Provenance from '../Provenance';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  PAGE_TITLE,
  SMALL_SCREEN_SIZE,
  SHOW_LINK_TO_REGISTRATION,
  ENABLE_LINK_TO_PAPA_REGISTRATION,
  isInSaleStage,
} from '../../utils/constants';
import { selectAppStage } from '../../state/app/state';
const Presale = lazy(() => import('../Sale/'));
const Sales = lazy(() => import('../Sale/sales'));

export default function App(): ReactElement {
  const [nftContract, setNftContract] = useState<Contract | undefined>();
  const [auctionContract, setAuctionContract] = useState<Contract | undefined>();
  const alert = useSelector(selectAlert);
  const appStage = useSelector(selectAppStage);
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery(`(max-width: ${SMALL_SCREEN_SIZE}px)`);

  function handleAlertClose(event: any, reason?: string) {
    if (reason === 'clickaway') return;
    dispatch(closeAlert());
  }

  const renderRouterPaths = useMemo(
    () => (
      <Routes>
        <Route
          path="/"
          element={
            <Page>
              <Home />
            </Page>
          }
        />
        <Route
          path="/tos"
          element={
            <Page title="Terms of Service">
              <Tos />
            </Page>
          }
        />
        <Route
          path="/provenance"
          element={
            <Page>
              <Provenance />
            </Page>
          }
        />
        {ENABLE_LINK_TO_PAPA_REGISTRATION ? (
          <Route
            path="/papa-register"
            element={
              <Page title="Registration">
                <Presale />
              </Page>
            }
          />
        ) : (
          <></>
        )}
        {SHOW_LINK_TO_REGISTRATION ? (
          <Route
            path="/register"
            element={
              <Page title="Registration">
                <Presale />
              </Page>
            }
          />
        ) : (
          <></>
        )}
        {isInSaleStage(appStage) ? (
          <>
            <Route path="/sales" element={<Navigate to="/sale" />} />
            <Route
              path="/sale"
              element={
                <Page title="Sales Zone">
                  <Sales nftContract={nftContract} auctionContract={auctionContract} />
                </Page>
              }
            />
          </>
        ) : (
          <></>
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    ),
    [nftContract, auctionContract, appStage]
  );

  const handleOnWalletConnected = useCallback(
    (nftContract: Contract | undefined, auctionContract: Contract | undefined) => {
      console.log('connected');
      setNftContract(nftContract);
      setAuctionContract(auctionContract);
    },
    []
  );

  return (
    <Suspense fallback={<div></div>}>
      <div className={'app' + (smallScreen ? ' smallScreen' : '')}>
        <Header onWalletConnected={handleOnWalletConnected}></Header>
        <div className="content">{renderRouterPaths}</div>
        <Footer></Footer>
        <Snackbar
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          open={alert.open}
          autoHideDuration={alert.autoHideDurationMs}
          onClose={handleAlertClose}
        >
          <Alert severity="warning">{alert.message}</Alert>
        </Snackbar>
        <Modal />
      </div>
    </Suspense>
  );
}
interface Props {
  title?: string;
}

export const Page: React.FC<Props> = ({ title, ...props }: React.PropsWithChildren<Props>) => {
  useEffect(() => {
    document.title = title != null ? `${title} - ${PAGE_TITLE}` : PAGE_TITLE;
  }, [title]);
  return <>{props.children}</>;
};
