import { TransactionResponse } from '@ethersproject/abstract-provider';

export enum TransactionReceiptStatus {
  FAILURE = 0,
  SUCCESS = 1,
}

export enum TransactionStatus {
  NEW,
  PROCESSING,
  SUCCESS_PRE_MINED,
  FAILURE_PRE_MINED,
  SUCCESS,
  FAILURE,
}

export function isPendingTx(status: TransactionStatus): boolean {
  return [
    TransactionStatus.NEW,
    TransactionStatus.PROCESSING,
    TransactionStatus.SUCCESS_PRE_MINED,
    TransactionStatus.FAILURE_PRE_MINED,
  ].includes(status);
}

export interface Transaction extends TransactionResponse {
  status: TransactionStatus;
  failureReason?: string;
}

export interface TransactionWithReason extends Omit<Transaction, 'failureReason'> {
  failureReason: string;
}

export interface TransactionBatch {
  transactions: Array<Transaction>;
  status: (tx: Transaction) => TransactionStatus;
}
