export enum ChainId {
  UNSPECIFIED = 0x0,
  MAINNET = 0x1,
  ROPSTEN = 0x3,
  RINKEBY = 0x4,
  GOERLI = 0x5,
  KOVAN = 0x2a,
}

export default ChainId;

export const parseChainId = (chainId: ChainId | string): ChainId => {
  if (typeof chainId === 'string') {
    return parseInt(chainId);
  } else {
    return chainId;
  }
};
