import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import 'smoothscroll-anchor-polyfill';
import reportWebVitals from './reportWebVitals';
import App from './pages/App';
import ScrollToTop from './components/ScrollToTop';
import store from './state';
import './index.scss';
import './assets/MonumentExtended-Regular.otf';

smoothscroll.polyfill();

render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);
}
